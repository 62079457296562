import React, { Component } from 'react'
import GatsbyLink from '../GatsbyLink';

const NavigationLink = props => {
  const {
    item,
    // closeNavigation,
    currentPath,
    wordpressUrl,
  } = props
  const { children, classes, url, title } = item
  const isParentLink = Boolean(children && children.length > 0)
  const pageUrl = url

  // console.log(currentPath + ' : ' + pageUrl);

  // // External Link Case
  // if (classes && classes.indexOf('external') !== -1) {
  //   return (
  //     <a
  //       href={pageUrl}
  //       target="_blank"
  //       rel="noopener noreferrer"
  //       dangerouslySetInnerHTML={{ __html: title }}
  //       // onClick={() => closeNavigation()}
  //     />
  //   )
  // }
  // Internal Link Case
  // const isActive =
  //   currentPath === pageUrl ||
  //   (isParentLink &&
  //     children.find(
  //       child => child.url === currentPath
  //     ))
  return (
    <GatsbyLink to={pageUrl}>{title}</GatsbyLink>
  )
}

const SubNavigation = props => {
  const { items, currentPath, wordpressUrl } = props
  return (
    <ul className="sub">
      {items.map(child => (
        <li key={child.order} className={`child ${child.classes}`}>
          <NavigationLink
            item={child}
            wordpressUrl={wordpressUrl}
            // closeNavigation={closeNavigation}
            currentPath={currentPath}
          />
        </li>
      ))}
    </ul>
  )
}

export default class Navigation extends Component {
  //   state = {
  //     subNavIsOpen: false,
  //     subNavIndex: null
  //   };

  //   toggleSubNavigation = (event, index) => {
  //     const {
  //       subNavIsOpen,
  //       subNavIndex: currentSubNavIndex,
  //     } = this.state;
  //     this.setState({
  //       subNavIsOpen: currentSubNavIndex === index ? !subNavIsOpen : true,
  //       subNavIndex: index
  //     });
  //   }

  render() {
    const {
      items,
      //   className,
      //   closeNavigation,
      currentPath,
      wordpressUrl,
      navOpen
    } = this.props
    // const {
    //   subNavIndex,
    //   subNavIsOpen,
    // } = this.state;
    return (
      <nav
        className={`${navOpen ? 'nav-open' : ''}`}
        role="navigation"
      >
        <ul>
          {items.map((navItem, index) => {
            const { order, children, classes, url, title } = navItem
            const isParent = children && children.length > 0
            return (
              <li
                key={order}
                // className={`${isParent ? 'parent' : ''} ${((subNavIndex === index) && subNavIsOpen) ? 'sub-nav-open' : ''} ${classes}`}
              >
                {/* {title} */}
                {/* {navItem.children} */}
                {/* {classes} */}
                {/* {url} */}
                <NavigationLink
                  wordpressUrl={wordpressUrl}
                  item={navItem}
                  // closeNavigation={closeNavigation}
                  currentPath={currentPath}
                />
                {children && children.length > 0 ? (
                  <SubNavigation
                    wordpressUrl={wordpressUrl}
                    items={children}
                    // closeNavigation={closeNavigation}
                    currentPath={currentPath}
                  />
                ) : null}
              </li>
            )
          })}
        </ul>
      </nav>
    )
  }
}
