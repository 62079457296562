import React from 'react'
import { Link } from 'gatsby'
import './Logo.scss'

export const Logo = props => {
  const { siteName, url, closeNavigation } = props
  return (
    <div className="logo-container">
      <Link
        className="logo"
        to={url}
        //   onClick={() => closeNavigation()}
      />
    </div>
  )
}
