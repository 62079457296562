import React from 'react';
import { Link } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';

export default class GatsbyLink extends React.Component {
  render() {
    const {to, activeClassName = "active", children, data, decode = true, ...other } = this.props

    if (to.endsWith('#/')) {
      return <span>{decode ? decodeEntities(children) : children}</span>
    }
    if (to.startsWith('/')) {
      return <Link activeClassName={activeClassName} {...other} to={to}>{decode ? decodeEntities(children) : children}</Link>
    }

    return (
      <a href={to} {...other}>{decode ? decodeEntities(children) : children}</a>
    )
  }
}
